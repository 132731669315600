<template>
  <b-container class="pt-3 pb-3 border-bottom bg-grey" fluid v-if="comment">
    <b-row :id="`comment-${ comment.identity }`">
      <b-col cols="auto">
        <div class="avatar-wrap">
          <nuxt-link :to="$userRoute({ name: 'u-uid'}, comment.account)">
            <ScanTraderAvatar width="45" height="45" :src="comment.account.avatar" />
          </nuxt-link>
        </div>
      </b-col>
      <b-col class="pl-0">
        <div class="info-wrap d-flex justify-content-between">
          <div>
            <div class="name st_color_main_light d-sm-inline-block mb-1">
              <span v-if="$userCheck.are(['service', 'organization'], comment.account)" class="align-middle">
                <span class="account-type p-0 ">
                  <svg-icon :name="$userBadge(comment.account, 'name')" :width="$userBadge(comment.account, 'width', '', 0.47)" :height="$userBadge(comment.account, 'height', '', 0.47)" />
                  <nuxt-link v-if="$userCheck.is('service', comment.account) && _.get(comment.account, 'belongOrgInfo.name.nick', 0)" :to="$userRoute({ name: 'o-oas' }, comment.account.belongOrgInfo)">
                    <svg-icon
                      v-b-tooltip.hover.focus.top="comment.account.belongOrgInfo.name.nick"
                      :width="$userBadge(comment.account.belongOrgInfo, 'width', '', 0.47)"
                      :height="$userBadge(comment.account.belongOrgInfo, 'height', '', 0.47)"
                      :name="$userBadge(comment.account.belongOrgInfo, 'name')" />
                  </nuxt-link>
                </span>
              </span>
              <span class="align-middle">
                <nuxt-link class="st_color_inherit" :to="$userRoute({ name: 'u-uid'}, comment.account)">
                  {{ comment.account.name.nick }}{{ (comment.account.belongOrg && _.get(comment.account, 'name.real', '')) ? `．${comment.account.name.real || ''}` : '' }}
                </nuxt-link>
              </span>
            </div>
          </div>
          <div class="d-inline-block text-right time st_color_gray_dark">
            {{ $moment(comment.status.created).fromNow() }}
          </div>
        </div>
        <h6 class="post-date mt-1" />
        <div class="w-100-break mt-1 mb-1 st_color_gray_darker" v-html="$utils.autoLinkText(comment.content)" />
        <div class="inside-image-cursor-pointer" v-if="comment.image" v-viewer>
          <b-img class="w-100 mt-2 pt-2" fluid :src="comment.image" />
        </div>
      </b-col>
    </b-row>
    <b-row v-if="commentDeletable">
      <b-col cols="12" class="text-right mt-2">
        <div class="d-inline-block">
          <b-button class="btn-delete st_color_gray_dark" @click.prevent="removeComment()">
            <i class="material-icons align-middle st_color_gray_dark">
              clear
            </i>
            <span class="align-middle st_color_gray_dark">
              刪除
            </span>
          </b-button>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
  import { mapActions } from 'vuex'

  export default {
    props: {
      comment: {
        type:    Object,
        default: null,
      },
      ownerAccount: {
        type:    Object,
        default: null,
      },
    },
    data () {
      return {}
    },
    computed: {
      commentDeletable () {
        return (
          this.$userCheck.isLogin()
          && (
            this.$userCheck.serialIsOwn(this.comment.account.serial)
            || this.$userCheck.haveOwnRules(['admin', 'contentAdmin', 'contentComment'], this.comment.account)
            || this.$userCheck.isAdmin()
            || this.$userCheck.serialIsOwn(this.ownerAccount.serial)
            || this.$userCheck.haveOwnRules(['admin', 'contentAdmin', 'contentComment'], this.ownerAccount)
          )
        )
      },
    },
    methods: {
      ...mapActions('api/comment', ['deleteComment']),
      editComment () {
      },
      removeComment () {
        this.$bvModal.msgBoxConfirm('確認要刪除回覆？', {
          size:        'sm',
          buttonSize:  'sm',
          okVariant:   'danger',
          okTitle:     '確定',
          cancelTitle: '取消',
          footerClass: 'p-2 border-0',
          centered:    true,
        })
          .then(value => {
            const option = {
              identity: this.comment.identity,
            }

            value && this.deleteComment(option)
              .then(() => {
                this.$emit('update')
              })
          })
          .catch(() => {
            // An error occurred
          })
      },
    },
  }
</script>

<style lang="scss" scoped>
  .name {
    font-size: $st_font-size-base;
    letter-spacing: $st_font_letter-spacing;
  }

  .time {
    font-size: $st_font-size-smaller;
    font-weight: $st_font-weight-light;
    letter-spacing: $st_font_letter-spacing;
    text-align: right;
  }

  .btn-delete {
    padding: 3px 5px;
    font-size: $st_font-size-smaller;
    background: white;
    border: solid 2px rgba(184, 188, 195, 0.5);

    i {
      font-size: $st_font-size-base;
    }

    &:focus, &:active {
      @important {
        color: $st_color_gray_dark;
        background: white;
        border: solid 2px rgba(184, 188, 195, 0.5);
      }
    }
  }

  .bg-grey {
    background-color: rgba(240, 241, 243, 0.3);
  }
</style>
