<template>
  <div v-observe-visibility="observeVisibility" v-if="activity" class="w-100" v-show="!data || !data.error">
    <b-container
      fluid
      class="post-view pt-3 px-0 activity"
      v-if="
        data &&
          !data.error &&
          (activity.kind !== 'collection' || (activity.kind === 'collection' && data && data.type !== 'article'))
      ">
      <!--用戶資訊-->
      <b-row noGutters class="activity-user-info justify-content-start align-items-top mb-2">
        <b-col cols="auto" class="pl-3 d-none d-md-block">
          <div class="avatar-wrap text-center">
            <nuxt-link :to="$userRoute({ name: 'u-uid' }, activity.account)">
              <ScanTraderAvatar width="40" height="40" alt="Image" :src="activity.account.avatar" lazy />
            </nuxt-link>
          </div>
        </b-col>
        <b-col>
          <b-container fluid class="p-0 frame-user-activity-view-card">
            <b-row noGutters>
              <b-col class="pr-0 flex-fill">
                <div class="info-wrap d-flex">
                  <div class="flex-fill pl-md-3">
                    <nuxt-link
                      :to="$userRoute({ name: 'u-uid' }, activity.account)"
                      class="d-md-none d-lg-none d-xl-none">
                      <ScanTraderAvatar
                        class="align-top mr-1"
                        width="40"
                        height="40"
                        alt="Image"
                        :src="activity.account.avatar"
                        lazy />
                    </nuxt-link>
                    <h5 class="name d-inline-block st_color_main_light">
                      <template v-if="$userCheck.are(['service', 'organization'], activity.account)">
                        <span class="account-type">
                          <svg-icon
                            :name="$userBadge(activity.account, 'name')"
                            :width="$userBadge(activity.account, 'width')"
                            :height="$userBadge(activity.account, 'height')" />
                          <nuxt-link
                            v-if="
                              $userCheck.is('service', activity.account) &&
                                _.get(activity.account, 'belongOrgInfo.name.nick', 0)
                            "
                            :to="$userRoute({ name: 'o-oas' }, activity.account.belongOrgInfo)">
                            <svg-icon
                              v-b-tooltip.hover.focus.top="activity.account.belongOrgInfo.name.nick"
                              :width="$userBadge(activity.account.belongOrgInfo, 'width')"
                              :height="$userBadge(activity.account.belongOrgInfo, 'height')"
                              :name="$userBadge(activity.account.belongOrgInfo, 'name')" />
                          </nuxt-link>
                        </span>
                      </template>
                      <span class="align-middle">
                        <nuxt-link class="st_color_inherit" :to="$userRoute({ name: 'u-uid' }, activity.account)">
                          {{ activity.account.name.nick }}{{ this.getRealName() }}
                        </nuxt-link>
                        <span
                          class="activity_name st_color_gray_darker"
                          v-if="
                            activity.kind === 'article' &&
                              data &&
                              (data.kind === 'text' || data.kind === 'audio' || data.kind === 'video')
                          ">
                          發佈了一則新觀點
                        </span>
                        <span v-if="activity.kind === 'collection' && data.type === 'account'">
                          關注了
                          <span class="text-focus-man st_color_main_lightest">
                            {{ _.get(data, 'name.nick', '')
                            }}{{ data.belongOrg && _.get(data, 'name.real', '') ? `．${data.name.real || ''}` : '' }}
                          </span>
                        </span>
                        <div class="time st_color_gray_dark">
                          <span class="align-middle">
                            {{ $utils.timeFormatDefault(activity.status.updated) }}
                          </span>

                          <!-- 發佈「訂閱」動態－訂閱者畫面 標籤 -->
                          <span v-if="data">
                            <span v-if="activity.kind === 'article' && data.kind === 'news'">
                              <span v-if="data.channels.length === 0 || (data.channels.length > 0 && data.content)">
                                <span class="st_color_sub py-3 ml-1">
                                  <i class="fa fa-bullhorn" v-if="data.channels.length > 0" />
                                  <span
                                    v-for="(channel, channelIndex) in data.channels"
                                    :key="channel.identity"
                                    class="d-inline text-channel">
                                    <span>{{ channel.title }}</span>
                                    <span v-if="data.channels.length > 1 && channelIndex !== data.channels.length - 1">
                                      ，
                                    </span>
                                  </span>
                                </span>
                              </span>
                              <span v-else class="ml-2">
                                <span class="st_color_sub py-3 ml-1">
                                  <i class="fa fa-bullhorn" v-if="data.channels.length > 0" />
                                  <span
                                    v-for="(channel, channelIndex) in data.channels"
                                    :key="channel.identity"
                                    class="d-inline text-channel">
                                    <span>{{ channel.title }}</span>
                                    <span v-if="data.channels.length > 1 && channelIndex !== data.channels.length - 1">
                                      ，
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </div>
                      </span>
                    </h5>
                  </div>
                  <div class="mr-2">
                    <div class="text-right frame-dot">
                      <b-dropdown id="userdown" right noCaret v-if="activityEditable" class="bg-none border-none">
                        <template slot="button-content">
                          <i class="material-icons"> more_vert </i>
                        </template>
                        <b-dropdown-item-button
                          v-if="activity.kind === 'article' && data && data.kind === 'news'"
                          @click="editStatus = true"
                          class="dropdown-item-edit">
                          編輯
                        </b-dropdown-item-button>
                        <b-dropdown-item-button @click.prevent="removeActivity" class="dropdown-item-delete">
                          刪除
                        </b-dropdown-item-button>
                      </b-dropdown>
                    </div>
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-container>

          <b-row class="activity-content pl-3 pr-3" noGutters>
            <div class="w-100" v-if="data">
              <!-- 個人動態 -->
              <div v-if="activity.kind === 'article' && data.kind === 'news'">
                <div v-if="data.channels.length === 0 || (data.channels.length > 0 && data.content)">
                  <div
                    class="activity__content w-100-break user-select-none pt-2 brief st_color_gray_darker"
                    @cut.prevent
                    @copy.prevent
                    @paste.prevent
                    @contextmenu.prevent
                    ref="activityContent"
                    v-if="!editStatus"
                    v-html="$utils.autoLinkText(data.content)" />
                  <div class="w-100 img-cover inside-image-cursor-pointer" v-viewer v-if="data.cover && !editStatus">
                    <b-img class="img" :src="data.cover" />
                  </div>
                  <EditActivityForm
                    v-if="editStatus"
                    :articleData="data"
                    @cancelEdit="cancelEdit"
                    @updateArticle="updateArticle" />
                </div>
                <!-- 訂閱用戶專屬無權限 -->
                <div v-else>
                  <div class="text-subscribe-only st_color_gray_dark py-3">
                    此為訂閱用戶專屬動態
                  </div>
                </div>
              </div>
              <!-- 個人競技場 -->
              <div v-if="activity.kind === 'article' && data.kind === 'portfolio'">
                <!-- TODO: wait for real data to render -->
                <div class="activity-uid-des form-border">
                  <div>
                    <span class="mr-2"> 多單 </span>
                    <span class="mr-2"> 買進 </span>
                    <span class="mr-2"> 黑松 </span>
                    <span>(1234)</span>
                  </div>
                  <div>
                    <span class="fw-600 mr-2"> 成交價 </span>
                    <span>$ 12.74</span>
                    <span class="fw-600 ml-4 mr-2"> 持倉比例 </span>
                    <span>12%</span>
                  </div>
                </div>
              </div>
              <!-- 發布新觀點 -->
              <div
                v-if="
                  activity.kind === 'article' &&
                    (data.kind === 'text' || data.kind === 'audio' || data.kind === 'video')
                ">
                <div class="bdc-light mt-2">
                  <ArticleCardActivity :cardData="data" :activity="activity" />
                </div>
              </div>
              <!-- 我關注了帳號 -->
              <div
                class="post__collection post__collection__account"
                v-if="activity.kind === 'collection' && data.type === 'account'">
                <div>
                  <AccountCardActivity :account="data" />
                </div>
              </div>
              <!-- 我關注了觀點 -->
              <div
                class="post__collection post__collection__article"
                v-if="activity.kind === 'collection' && data.type === 'article'">
                <div>
                  <span>{{ activity.account.name.nick }}{{ this.getRealName() }}的觀點，快進來了解吧！</span>
                </div>
                <div class="border bdc-lightgray mt-2 w-100 pl-5 pr-5">
                  <ArticleCardActivity :cardData="data" />
                </div>
              </div>
              <!--注意～大慧慧的專屬服務更新囉-->
              <ChannelCardService v-if="false" />
              <!--注意～大慧慧成功上架成為認證達人囉-->
              <ExpertCard v-if="false" :show_price="false" />
            </div>
          </b-row>
        </b-col>
      </b-row>
      <div
        v-if="activity.likes || activity.commentReply || activity.comments"
        class="st_none-none-smaller st-md_none-none-small activity__reaction-count">
        <template v-if="activity.likes">
          {{ activity.likes }}個讚
        </template>
        <template v-if="activity.likes && (activity.commentReply || activity.comments)">
          ，
        </template>
        <span v-if="activity.commentReply || activity.comments" @click.prevent="$refs.commentBtn.click()">
          {{ activity.commentReply || activity.comments }}則留言
        </span>
      </div>
      <hr class="st_background_gray_lighter">
      <div class="activity__reaction">
        <b-button
          class="activity__likes activity__reaction-btn st_none-none-small"
          :class="{
            'activity__likes--active': likeState,
          }"
          @click.prevent="updateLikeState">
          <i
            :class="{
              'material-icons-outlined': !likeState,
              'material-icons': likeState,
            }"
            class="activity__icon">
            thumb_up
          </i>
          讚
        </b-button>
        <b-button
          @click.prevent="loadCommentList(true)"
          ref="commentBtn"
          class="activity__comments activity__reaction-btn st_none-none-small">
          <i class="far fa-comment-alt activity__icon" />
          留言
        </b-button>
        <b-dropdown
          lazy
          noCaret
          right
          class="activity__reaction-btn"
          toggle-class="activity__share st_none-none-small"
          menu-class="activity__share-menu">
          <template #button-content>
            <svg-icon
              class="activity__icon"
              name="icon_share-wire-light"
              :width="isMobile ? 18 : 20"
              :height="isMobile ? 18 : 20" />
            分享
          </template>
          <ShareNetwork
            v-if="isMounted"
            :description="`${$utils.filterAllHtmlTags(metaInfo.description, 150)}...`"
            :quote="`${$utils.filterAllHtmlTags(metaInfo.description, 150)}...`"
            :title="metaInfo.title"
            :url="generateSharingUrl(activity)"
            tag="b-dropdown-item"
            network="facebook">
            <i class="fab fa-facebook-square st_none-none-biggest mr-2" />
            <span class="activity__share-item"> 分享到FaceBook </span>
          </ShareNetwork>
          <ShareNetwork
            v-if="isMounted"
            :description="`${$utils.filterAllHtmlTags(metaInfo.description, 150)}...`"
            :title="metaInfo.title"
            :url="generateSharingUrl(activity)"
            tag="b-dropdown-item"
            network="line">
            <i class="fab fa-line st_none-none-biggest mr-2" />
            <span class="activity__share-item"> 分享給LINE好友 </span>
          </ShareNetwork>
          <b-dropdown-item @click.prevent="copySharingUrl(activity)">
            <svg-icon class="mr-2" name="icon_copy-link-light" width="23" height="32" />
            <span class="activity__share-item"> 複製連結 </span>
          </b-dropdown-item>
        </b-dropdown>
      </div>
      <b-row noGutters>
        <b-col>
          <div v-if="viewComment" class="bg-white">
            <div class="commentList" v-if="comments.length > 0">
              <ViewActivityComment
                v-for="comment in comments"
                :key="comment.identity"
                :comment="comment"
                :ownerAccount="activity.account"
                @update="loadCommentList" />
            </div>
            <div class="commentLoading w-100 py-3 text-center" v-else-if="commentLoading">
              <i class="fa fa-spinner fa-spin fs-26px" />
            </div>
            <ViewActivityComment v-else />
            <PostActivityComment :identity="activity.identity" @update="loadCommentList" />
          </div>
        </b-col>
      </b-row>
    </b-container>
    <div v-else-if="data" v-show="false" />
    <ViewActivityLoading v-else />
  </div>
</template>

<script>
  import AccountCardActivity from '~/components/cards/AccountCardActivity'
  import ArticleCardActivity from '~/components/cards/ArticleCardActivity'
  import ChannelCardService from '~/components/cards/ChannelCardService'
  import EditActivityForm from '~/components/forms/EditActivityForm'
  import ExpertCard from '~/components/cards/ExpertCard'
  import PostActivityComment from '~/components/forms/PostActivityComment'
  import ViewActivityComment from '~/components/views/ViewActivityComment'
  import ViewActivityLoading from '~/components/views/ViewActivityLoading'

  import { mapActions, mapGetters } from 'vuex'

  export default {
    components: {
      AccountCardActivity,
      ArticleCardActivity,
      ChannelCardService,
      EditActivityForm,
      ExpertCard,
      PostActivityComment,
      ViewActivityComment,
      ViewActivityLoading,
    },
    props: {
      activity: {
        type:     Object,
        required: true,
      },
      delay: {
        type:    Number,
        default: 2500,
      },
      threshold: {
        type:    Number,
        default: 0.5,
      },
      canAddStSourceHistory: {
        type:    Boolean,
        default: false,
      },
      canTracking: {
        type:    Boolean,
        default: false,
      },
    },
    data () {
      return {
        like:              this.activity.like || null,
        viewComment:       false,
        commentLoading:    true,
        comments:          [],
        data:              null,
        editStatus:        false,
        observeVisibility: false,
        waitUntilTimeUp:   null,
        isInitial:         true,
      }
    },
    computed: {
      ...mapGetters(['user']),
      headerText () {
        let text = ''
        switch (this.activity.kind) {
          case 'article':
            if (this.data && this.data.kind === 'portfolio') text = '競技場快訊'
            break
          case 'collection':
            if (this.data && this.data.type === 'account') {
              text = `${this.activity.account.name.nick}${this.getRealName()}關注了`
            } else {
              text = `${this.activity.account.name.nick}${this.getRealName()}收藏了`
            }
            break
          case 'channel':
            text = `嘿，${this.activity.account.name.nick}${this.getRealName()}有新的服務上架囉`
            break
          case 'service':
            text = `注意～${this.activity.account.name.nick}${this.getRealName()}成功上架成為認證達人囉`
            break
        }
        return `333${text}`
      },
      accessComments () {
        return !(
          this.activity.kind === 'article'
          && this.data
          && this.data.kind === 'news'
          && this.data.channels.length > 0
          && !this.data.content
        )
      },
      activityEditable () {
        if (!_.get(this, 'activity.account.identity', null)) {
          return false
        }
        return (
          this.$userCheck.isSame(this.activity.account)
          || this.$userCheck.isAdmin()
          || (_.get(this.activity, 'kind', null) !== 'article'
            && this.$userCheck.haveOwnRules(
              ['admin', 'contentAdmin', 'contentPaidEditor', 'contentFreeEditor'],
              this.activity.account,
            ))
          || (_.get(this.activity, 'kind', null) === 'article'
            && this.$userCheck.haveOwnRules(
              ['admin', 'contentAdmin'].concat(
                _.get(this.data, 'channels.length', 0) ? ['contentPaidEditor'] : ['contentFreeEditor'],
              ),
              this.activity.account,
            ))
        )
      },
      likeState () {
        return !_.isNil(this.like) || false
      },
      isMobile () {
        return this.windowWidth < 768
      },
      metaInfo () {
        return {
          title: `${_.get(this.activity, 'account.name.nick', null) || '某人'}${this.getRealName()}的動態${
            process.env.SEO.titleSplit
          }`,
          description: this.activity.summary || this.data.summary || '',
        }
      },
    },
    watch: {
      '$route.hash': 'checkAndScrollTo',
    },
    methods: {
      ...mapActions('api/article', ['getArticle']),
      ...mapActions('api/collection', ['getCollection']),
      ...mapActions('api/account', ['getAccount']),
      ...mapActions('api/activity', ['deleteActivity']),
      ...mapActions('api/reaction', ['getReaction', 'createReaction', 'deleteReaction']),
      ...mapActions('api/comment', ['getComments']),
      ...mapActions('api/history', ['createHistory']),

      getRealName () {
        return this.activity.account.belongOrg && _.get(this.activity.account, 'name.real', '')
          ? `．${this.activity.account.name.real || ''}`
          : ''
      },
      removeActivity () {
        this.$bvModal
          .msgBoxConfirm('確認要刪除動態？', {
            size:        'sm',
            buttonSize:  'sm',
            okVariant:   'danger',
            okTitle:     '確定',
            cancelTitle: '取消',
            footerClass: 'p-2 border-0',
            centered:    true,
          })
          .then(value => {
            const option = {
              identity: this.activity.identity,
            }

            value
              && this.deleteActivity(option).then(() => {
                this.$emit('update')
              })
          })
          .catch(() => {
          // An error occurred
          })
      },
      updateLikeState () {
        if (this.user.serial !== 0) {
          const { like } = this
          if (_.isNil(like)) {
            this.like = true
            // eslint-disable-next-line vue/no-mutating-props
            this.activity.likes++
            const reaction = {
              kind:    'activity',
              index:   this.activity.identity,
              name:    'like',
              account: this.user.my_info.identity,
            }
            this.createReaction(reaction).then(data => {
              this.like = _.isNil(data.error) ? data.identity : null
            })
          } else {
            this.like = null
            // eslint-disable-next-line vue/no-mutating-props
            this.activity.likes--
            const reactionOption = {
              identity: like,
            }
            this.deleteReaction(reactionOption)
          }
        } else {
          this.$toast.show('登入後即可對動態按讚').goAway(1500)
        }
      },
      loadCommentList (changeShowState) {
        if (!this.accessComments) return
        if (typeof changeShowState !== 'boolean') changeShowState = null
        if (changeShowState) {
          this.viewComment = !this.viewComment
        }
        if (this.viewComment) {
          if (changeShowState) {
            this.commentLoading = true
          }
          const commentsOption = {
            kind:   'activity',
            index:  this.activity.identity,
            // fields: ['identity', 'content', 'image', 'replies', 'account.kind', 'account.organization.alias', 'account.organization.kind', 'account.identity', 'account.serial', 'account.avatar', 'account.name', 'account.service', 'account.ownRoles', 'account.belongOrg', 'account.belongOrgInfo', 'status.created'],
            fields: [
              'identity',
              'content',
              'image',
              'replies',
              'like',
              'likes',
              'account.kind',
              'account.organization.alias',
              'account.organization.kind',
              'account.identity',
              'account.serial',
              'account.avatar',
              'account.name',
              'account.service',
              'account.ownRoles',
              'account.belongOrg',
              'account.belongOrgInfo',
              'status.created',
            ],
            sort: {
              'status.created': 1,
            },
          }
          this.getComments(commentsOption).then(data => {
            this.comments = !data.error && data.data.length > 0 ? data.data : []
            // eslint-disable-next-line vue/no-mutating-props
            this.activity.comments = _.get(data, 'page.count', 0)
            this.commentLoading = false
          })
        }
      },
      checkAndScrollTo (watchOldValue) {
        if (this.$route.hash && !this.$route.hash.includes('access_token')) {
          let i = 0
          const elementCheckingInterval = setInterval(() => {
            i++
            if (!this.$route.hash) {
              clearInterval(elementCheckingInterval)
            }
            try {
              if (document && document.querySelector(this.$route.hash)) {
                this.$scrollTo(this.$route.hash)
                clearInterval(elementCheckingInterval)
              }
            } catch (e) {
              clearInterval(elementCheckingInterval)
            }
            if (i > 50) {
              clearInterval(elementCheckingInterval)
            }
          }, 100)
          this.loadCommentList(!watchOldValue)
        }
      },
      cancelEdit () {
        this.editStatus = false
      },
      async updateArticle () {
        const identity = this.activity.index
        switch (this.activity.kind) {
          case 'article':
            const articleOption = {
              identity: identity,
              kind:     ['text', 'audio', 'video', 'news'],
              fields:   [
                'identity',
                'channels',
                'kind',
                'cover',
                'account.identity',
                'account.name',
                'account.service',
                'account.kind',
                'account.ownRoles',
                'account.belongOrg',
                'account.belongOrgInfo',
                'title',
                'summary',
                'content',
                'tags',
                'view',
                'likes',
                'commentReply',
                'marketTags',
              ],
            }
            this.data = await this.getArticle(articleOption).then(data => {
              if (!data.error && data.identity) {
                return data
              }
              return { error: true }
            })
            break
          case 'collection':
            const collectionOption = {
              identity: identity,
            }
            const collection = await this.getCollection(collectionOption)
            const collectionIndex = collection.index
            switch (collection.kind) {
              case 'account':
                const accountOption = {
                  identity: collectionIndex,
                }
                this.data = {
                  account: this.activity.account,
                  ...(await this.getAccount(accountOption)),
                }
                break
              case 'article':
                this.data = { error: true }
                // Didn't have collection article screen
                // this.data = await this.getArticle(articleOption)
                break
              default:
                this.data = { error: true }
                break
            }
            break
          case 'channel':
          case 'service':
          default:
            this.data = { error: true }
            break
        }
        if (this.data.error) {
          this.$emit('noData')
          return
        }
        this.editStatus = false
      },
      visibilityChanged (isVisible) {
        if (isVisible) {
          this.$emit('component:isVisible')
          this.waitUntilTimeUp = setTimeout(async () => {
            this.addHistory()
            this.$emit('tracking:addHistory')
            this.observeVisibility = false
            await this.$nextTick()
            this.$emit('tracking:disabled')
          }, this.delay)
        } else {
          clearTimeout(this.waitUntilTimeUp)
          !this.isInitial && this.$emit('tracking:cancel')
        }
        this.isInitial = false
      },
      addHistory () {
        if (!this.accessComments) return
        const historyOption = {
          account:     this.user.serial === 0 ? 0 : this.user.my_info.identity || 0,
          kind:        'activity',
          index:       this.activity.identity,
          fingerprint: this.user.fingerprint,
        }
        this.createHistory(historyOption)

        if (
          this.canAddStSourceHistory
          && this.$route.query.stSource
          && (this.$route.query.stSource === 'linebotPush' || this.$route.query.stSource === 'stNotifyPush')
        ) {
          const historySourceOption = {
            account:     this.user.serial === 0 ? 0 : this.user.my_info.identity || 0,
            kind:        'article',
            index:       `${this.$route.query.stSource}_${this.activity.identity}`,
            fingerprint: this.user.fingerprint,
          }
          this.createHistory(historySourceOption)
          this.$router.replace({
            name:   this.$route.name,
            params: this.$route.params,
            hash:   this.$route.hash,
          })
        }
      },
      generateSharingUrl ({ identity }) {
        if (!identity) return ''
        return `${window.location.origin}/activity/${identity}`
      },
      copySharingUrl (activity) {
        this.$copyText(this.generateSharingUrl(activity))
          .then(() => {
            this.$toast.show('已成功複製網址').goAway(3000)
          })
          .catch(() => {
            this.$toast.show('複製網址失敗').goAway(3000)
          })
      },
    },
    async mounted () {
      this.checkAndScrollTo()
      await this.updateArticle()
      if (this.accessComments && this.canTracking) {
        this.observeVisibility = {
          callback:     this.visibilityChanged,
          intersection: {
            threshold: this.threshold,
          },
        }
        await this.$nextTick()
        this.$emit('tracking:enabled')
      }
    },
    beforeDestroy () {
      clearTimeout(this.waitUntilTimeUp)
      this.observeVisibility && this.$emit('tracking:disabled')
    },
  }
</script>

<style lang="scss" scoped>
.activity-user-info {
  .avatar-wrap {
    cursor: pointer;

    &:hover {
      opacity: 0.75;
    }
  }
}

.form-insert-pic__button {
  float: right;
  margin-top: -34px;
}

.post-view {
  position: relative;

  hr {
    height: 2px;
    border: none;
    margin: 0;
    padding: 0;
  }

  .name {
    font-size: $st_font-size-base;
    line-height: 1.5;
    letter-spacing: $st_font_letter-spacing;
    overflow: hidden;
    width: calc(100% - 50px);
  }

  .time {
    font-size: $st_font-size-small;
    font-weight: $st_font-weight-light;
    letter-spacing: $st_font_letter-spacing;
  }

  .form-border {
    border: 1px solid #acbbc3;
    border-radius: 15px;
    padding: 20px;
  }

  .activity-control-panel {
    @media (min-width: 768px) {
      visibility: hidden;
      position: absolute;
      right: 0;
      top: 10px;
      z-index: 1;
    }
  }

  &:hover {
    .activity-control-panel {
      visibility: visible;
    }
  }
}

.post-comment-info {
  .comment_triangle {
    transition: all 0.2s;
    display: inline-block;
    color: #3d4a51;
    font-size: 20px;

    &.open {
      transform: rotate(-90deg);
    }
  }
}

.bg-none {
  background: none;
}

.border-none {
  border: none;
}

.frame-bottom {
  font-size: $st_font-size-small;
  letter-spacing: $st_font_letter-spacing;

  .btn-bottom {
    font-size: $st_font-size-small;
    font-weight: normal;
    letter-spacing: $st_font_letter-spacing;
    color: $st_color_gray_dark;

    @important {
      background: none;
    }

    &.actived {
      color: $st_color_sub;
    }

    &:active {
      @important {
        color: $st_color_gray_darker;
      }
    }

    .brief {
      font-size: $st_font-size-base;
      line-height: 1.5;
      letter-spacing: $st_font_letter-spacing;
    }

    .activity_name {
      font-size: $st_font-size-small;
      font-weight: $st_font-weight-light;
      line-height: 1.57;
      letter-spacing: $st_font_letter-spacing;
    }
  }
}

.text-subscribe-only {
  font-size: $st_font-size-small;
  font-weight: $st_font-weight-medium;
  letter-spacing: $st_font_letter-spacing;
  background: rgba(236, 238, 239, 0.3);
  border-radius: 2px;
  text-align: center;
}

.text-focus-man {
  font-size: $st_font-size-base;
  font-weight: $st_font-weight-light;
  letter-spacing: 0.9px;
  word-break: break-all;
}

.text-channel {
  font-size: $st_font-size-small;
  font-weight: $st_font-weight-medium;
  letter-spacing: $st_font_letter-spacing;
  word-break: break-all;
}

.icon-disabled {
  @important {
    opacity: 0.2;
  }
}

.img-cover {
  width: 100%;

  img {
    max-width: 100%;
    height: auto;
  }
}

.icon-bottom {
  font-size: 19px;
}

.dropdown-item-delete {
  cursor: pointer;

  &:hover {
    background: rgba(112, 116, 125, 0.15);
  }
}

.dropdown-item-edit {
  cursor: pointer;
}

.activity {
  $activity: &;

  &__reaction {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 10px;

    @include media-breakpoint-up(md) {
      padding: 5px 20px;
    }
  }

  &__reaction-btn {
    flex: 1;
    max-width: 154px;
  }

  &__likes,
  &__comments {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    border: none;
    position: relative;
    height: 36px;
    border-radius: 6px;
    color: $st_color_gray_dark;

    @include media-breakpoint-up(md) {
      &:hover {
        background-color: #eaedf2;
      }

      &:active {
        color: $st_color_gray_dark;
        background-color: #eaedf2;
      }
    }

    &--active {
      color: $st_color_sub;

      #{$activity}__icon {
        color: $st_color_sub;
      }

      &:active {
        color: $st_color_sub;
      }
    }
  }

  &__icon {
    margin-right: 8px;
    font-size: $st_font-size-big;
    color: $st_color_gray;

    @include media-breakpoint-up(md) {
      font-size: 20px;
    }
  }

  &__reaction-count {
    text-align: right;
    margin-bottom: 6px;
    cursor: pointer;
    user-select: none;
    padding: 0 16px;
    color: $st_color_gray_dark;
  }

  /deep/ {
    #{$activity}__content {
      flex: 1;
      user-select: none;
      margin-bottom: 16px;

      markettag.mention[data-tickerSymbol]:after {
        content: attr(data-namePrimary) '(' attr(data-tickerSymbol) ')';
      }
    }
    #{$activity}__share {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;
      border: none;
      position: relative;
      background-color: transparent;
      height: 36px;
      border-radius: 6px;
      color: $st_color_gray_dark;

      &:active {
        background-color: transparent;
        color: $st_color_gray_dark;
      }

      @include media-breakpoint-up(md) {
        &:hover {
          background-color: #eaedf2;
        }

        &:active,
        &[aria-expanded='true'] {
          color: $st_color_gray_dark;
          background-color: #eaedf2;
        }
      }
    }

    #{$activity}__share-item {
      color: $st_color_gray_dark;
    }

    #{$activity}__share-menu {
      border-radius: 6px;
      padding: 0;
      overflow: hidden;

      &::before {
        display: none;
      }

      .dropdown-item {
        padding: 11px 30px;
        min-width: 240px;
        display: flex;
        align-items: center;
        color: $st_color_gray_light;

        &:hover,
        &:active {
          background-color: #eaedf2;
          color: $st_color_gray_light;
        }
      }
    }
  }
}
</style>
