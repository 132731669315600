<template>
  <b-container class="frame-user-activity-post">
    <b-row>
      <b-col class="px-0">
        <b-form @submit.prevent="editActivity">
          <div class="d-flex">
            <div class="flex-fill w-100 pr-2 editor">
              <CommonFroalaEditor
                class="st_none-none-big st-md_none-none-bigger"
                :placeholderText="`${articleData.account.name.nick || user.my_info.name.nick}，最近有什麼有趣觀察嗎？`"
                v-focus
                v-model="editedContent"
                ref="commonFroalaEditor" />
            </div>
            <div class="text-right" v-if="!editedCover">
              <span @click.prevent="onInsertImage" class="btn-image pointer">
                <i class="fa fa-image" />
              </span>
            </div>
            <b-form-file
              ref="imageUpdate"
              @change="onFileChange"
              v-show="false"
              accept="image/*"
              v-model="imageFile"
              plain
              class="d-none" />
          </div>

          <div v-if="editedCover">
            <div class="image-preview pointer" v-viewer @click.prevent="expandImage">
              <img :src="editedCover" alt="預覽" class="preview">
              <span class="btn-clear d-inline-block" @click.prevent="removeImage">
                <i class="material-icons"> clear </i>
              </span>
              <span class="btn-expand pointer">
                <i class="material-icons"> zoom_out_map </i>
              </span>
            </div>
          </div>
          <b-row class="align-items-center">
            <b-col v-if="channelsOptions.length > 0" class="pr-0">
              <md-checkbox class="st_color_main_light mr-0" v-model="paySetting" :disabled="userCanOnlySetChannels">
                限訂閱者觀看
              </md-checkbox>
            </b-col>

            <b-col class="text-right pl-0" :cols="channelsOptions.length > 0 ? 8 : 12">
              <b-button class="st_sub_s" @click="$emit('cancelEdit')">
                取消
              </b-button>
              <b-button
                type="submit"
                class="st_main_s"
                :disabled="editedContent.length === 0 || (paySetting && this.selectedChannels.length === 0)">
                儲存
              </b-button>
            </b-col>
          </b-row>

          <div v-if="paySetting && channelsOptions.length > 0">
            <hr>
            <div class="mt-3">
              <h5 class="st_color_gray text-note-title">
                誰可以看到這個訂閱內容？
                <span class="st_color_gray_dark text-note-brief"> (將及時推播至訂閱戶的個人LINE) </span>
              </h5>
            </div>

            <div>
              <md-checkbox
                class="st_color_main_light"
                v-model="channelsSelectAll"
                @change="channelsClickSelectAll"
                :value="true">
                全選
              </md-checkbox>
              <b-row class="frame-checkbox mt-1">
                <b-col
                  cols="6" sm="12" md="6" lg="6" xl="6"
                  v-for="(x, i) of channelsOption" :key="i">
                  <md-checkbox class="st_color_main_light" v-model="selectedChannels" :value="x.value">
                    {{ x.text }}
                  </md-checkbox>
                </b-col>
              </b-row>
            </div>
          </div>
        </b-form>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
  import CommonFroalaEditor from '~/components/forms/CommonFroalaEditor-v2'
  import { mapActions, mapGetters } from 'vuex'

  export default {
    components: { CommonFroalaEditor },
    directives: {
      focus: {
        inserted: el => {
          el.focus()
        },
      },
    },
    props: {
      articleData: {
        type:     Object,
        required: true,
      },
    },
    data () {
      return {
        editedContent:     this.articleData.content,
        editedCover:       this.articleData.cover,
        imageFile:         null,
        selectedChannels:  this.articleData.channels.map(el => el.identity),
        channelsSelectAll: false,
        paySetting:        false,
        channelsOptions:   [],
        initEdit:          false,
      }
    },
    computed: {
      ...mapGetters(['user']),
      channelsOption () {
        return this.channelsOptions && this.channelsOptions.length > 0
          ? this.channelsOptions.map(channel => ({
            value: channel.identity,
            text:  channel.title,
          }))
          : []
      },
      userCanSetChannels () {
        return (
          this.$userCheck.isSame(this.articleData.account)
          || this.$userCheck.isAdmin()
          || this.$userCheck.haveOwnRules(['admin', 'contentPaidEditor', 'contentAdmin'], this.articleData.account)
        )
      },
      userCanOnlySetChannels () {
        return (
          !this.$userCheck.isSame(this.articleData.account)
          && !this.$userCheck.isAdmin()
          && !this.$userCheck.haveOwnRules(['admin', 'contentAdmin'], this.articleData.account)
          && this.$userCheck.haveOwnRules(['contentPaidEditor'], this.articleData.account)
        )
      },
    },
    watch: {
      selectedChannels: 'checkChannelsSelectAll',
      paySetting:       'paySettingChange',
    },
    methods: {
      ...mapActions('api/upload', ['uploadFile']),
      ...mapActions('api/article', ['updateArticle']),
      ...mapActions('api/channel', ['getChannels']),
      expandImage (e) {
        e.target.parentNode.parentNode.querySelector('.preview').click()
      },
      onFileChange (e) {
        const files = e.target.files || e.dataTransfer.files
        if (!files.length) {
          return
        }
        const maxFileSizeMb = 5
        const fileSize = files[0].size / 1024 / 1024

        if (fileSize > maxFileSizeMb) {
          this.$toast.show(`檔案大小不得超過 ${maxFileSizeMb}Mb`).goAway(3000)
          e.target.value = ''
          return
        }

        if (files[0].type.match(/image.*/gi)) {
          this.createImage(files[0])
        } else {
          e.target.value = ''
        }
      },
      createImage (file) {
        const reader = new FileReader()
        const vm = this

        reader.onload = e => {
          vm.editedCover = e.target.result
        }
        reader.readAsDataURL(file)
      },
      onInsertImage () {
        this.$refs.imageUpdate.$el.click()
      },
      removeImage () {
        this.$refs.imageUpdate.$el.value = ''
        this.editedCover = null
        this.imageFile = null
      },
      channelsClickSelectAll (checked) {
        this.selectedChannels = checked ? this.channelsOptions.map(channel => channel.identity) : []
      },
      checkChannelsSelectAll (newVal) {
        if (newVal.length < this.channelsOptions.length) {
          this.channelsSelectAll = false
        } else if (newVal.length === this.channelsOptions.length) {
          this.channelsSelectAll = true
        } else {
          this.channelsSelectAll = false
        }
      },
      formatFormData (cover) {
        return {
          content:  this.editedContent.trim(),
          cover:    cover || '',
          channels: this.selectedChannels,
          identity: this.articleData.identity,
        }
      },
      async editActivity () {
        let cover = this.editedCover
        if (this.imageFile) {
          cover = await this.uploadFile(this.imageFile)
        }
        const article = this.formatFormData(cover)
        await this.updateArticle(article).then(data => {
          if (data.error) {
            this.loading = false
            this.$toast.show('儲存動態失敗，請稍後再試一次').goAway(3000)
            return false
          }
          this.$emit('updateArticle')
          return true
        })
      },
      paySettingChange () {
        if (!this.initEdit) {
          this.selectedChannels = this.paySetting ? this.channelsOption.map(channel => channel.value) : []
        }
        this.initEdit = false
      },
    },
    async created () {
      if (this.$userCheck.is('service', this.articleData.account) && this.userCanSetChannels) {
        const option = {
          account: [this.articleData.account.identity],
          fields:  ['identity', 'title', 'price', 'account.identity', 'hidden'],
        }
        this.channelsOptions = await this.getChannels(option).then(data => (!data.error && data.data.length > 0 ? data.data : []))
        if (this.selectedChannels.length > 0) {
          this.initEdit = true
          this.paySetting = true
          if (this.selectedChannels.length === this.channelsOptions.length) {
            this.channelsSelectAll = true
          }
        }
      }
    },
  }
</script>

<style lang="scss" scoped>
.editor {
  max-width: 95%;
}

.textarea {
  border: none;
  min-height: 36px;
  resize: none;
  outline: none;
  box-shadow: none;
}

.btn-image {
  font-size: 30px;
  opacity: 0.8;
}

.pointer {
  cursor: pointer;
}

.image-preview {
  display: inline-block;
  position: relative;
  width: 200px;
  height: 200px;
  line-height: 200px;
  overflow: hidden;
  text-align: center;
  border-color: rgba($st_color_gray_light, 0.35);
  border-style: dashed;
  border-width: 2px;
  background-color: white;
  z-index: 2;

  img {
    position: relative;
    max-width: 200px;
    max-height: 200px;
    vertical-align: middle;
    transition: 0.4s;
    z-index: -2;
  }

  .btn-clear {
    cursor: pointer;
    border-radius: 50%;
    color: white;
    width: 26px;
    height: 26px;
    padding-top: 2px;
    text-align: center;
    background-color: rgba(43, 44, 55, 0.5);
    right: 5px;
    top: 5px;
    position: absolute;
    z-index: 1;
    line-height: 0;

    i {
      font-size: $st_font-size-bigger;
    }
  }

  .btn-expand {
    border-radius: 50%;
    position: absolute;
    width: 26px;
    height: 26px;
    padding-top: 1px;
    text-align: center;
    left: 5px;
    top: 5px;
    color: white;
    background-color: rgba(43, 44, 55, 0.5);
    transition-property: top, left, transform, width, height;
    transition-duration: 0.4s;
    line-height: 0;

    i {
      transition-property: font-size;
      transition-duration: 0.4s;
    }
  }

  &:after {
    transition: 0.4s;
    content: '';
  }

  &:hover {
    &::after {
      content: '';
      background-color: rgba(0, 0, 0, 0.4);
      position: absolute;
      z-index: -1;
      height: 100%;
      bottom: 0;
      left: 0;
      right: 0;
    }

    .btn-expand {
      top: 50%;
      left: 50%;
      transform: translate(-60%, -60%);
      background: transparent;

      i {
        font-size: 36px;
      }
    }
  }
}

.text-note-title {
  font-size: $st_font-size-base;
  font-weight: $st_font-weight-medium;
  letter-spacing: $st_font_letter-spacing;
}

.text-note-brief {
  font-size: $st_font-size-small;
  font-weight: $st_font-weight-light;
  letter-spacing: 0.7px;
}
</style>
