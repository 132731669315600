<template>
  <b-container class="post-form pt-3 pb-3" fluid>
    <b-row v-if="$userCheck.isLogin()" class="align-items-top">
      <b-col class="ml-auto" cols="10" sm="8" lg="5" v-if="_.get(user, 'myOwnRoles.commentReply.length', 0)">
        <multiselect
          class="st-multiselect-commentReplySelect"
          :max-height="600"
          :options="user.myOwnRoles.commentReply"
          :allowEmpty="false"
          :searchable="false"
          :internalSearch="false"
          selectedLabel=""
          openDirection="bottom"
          placeholder="選擇發文身分"
          trackBy="identity"
          v-model="selectOwnRole">
          <template slot="singleLabel" slot-scope="{ option }">
            <ScanTraderAvatar class="st-accountTag__avatar" width="20" height="20" :src="option.avatar" />
            <span class="st-accountTag__name" v-b-tooltip.hover.top="`${option.name.nick}(U${ option.serial })`">{{ option.name.nick }}</span>
            <span class="st-accountTag__serial">(U{{ option.serial }})</span>
          </template>
          <template slot="option" slot-scope="{ option }">
            <ScanTraderAvatar class="st-accountTag__avatar" width="30" height="30" :src="option.avatar" />
            <span class="st-accountTag__name">{{ option.name.nick }}</span>
            <span class="st-accountTag__serial">(U{{ option.serial }})</span>
          </template>
          <span slot="noResult" v-show="false" />
          <span slot="noOptions" v-show="false" />
        </multiselect>
      </b-col>
      <b-col cols="12">
        <b-form @submit.prevent="addComment">
          <div class="d-flex bg-white px-3 py-2 frame-comment">
            <div class="avatar-wrap mr-2">
              <ScanTraderAvatar width="36" height="36" :src="(selectOwnRole || user.my_info).avatar" />
            </div>
            <div class="flex-fill">
              <textarea-autosize
                placeholder="留言給這篇動態" class="form-control border-0 textarea-content" v-model="form.content"
                @focus.native="onFocusTextarea" @blur.native="onBlurTextarea" />
            </div>
            <div class="pl-2">
              <span @click.prevent="onInsertImage" v-if="!form.image" class="btn-image pointer">
                <i class="fa fa-image" />
              </span>
              <b-form-file
                v-model="form.imageFile"
                ref="imageUpload"
                @change="onFileChange"
                v-show="false"
                accept="image/*"
                plain />
            </div>
          </div>
          <div class="mt-3 d-flex justify-content-between align-items-top" v-if="isTextareaFocus || form.content.length > 0 || form.image">
            <div class="pl-5">
              <div class="image-preview pointer" v-if="form.image" v-viewer @click.prevent="expandImage">
                <img :src="form.image" alt="預覽" class="preview">
                <span class="btn-clear" @click.prevent="removeImage">
                  <i class="material-icons">
                    clear
                  </i>
                </span>
                <span class="btn-expand pointer">
                  <i class="material-icons">
                    zoom_out_map
                  </i>
                </span>
              </div>
            </div>
            <div class="text-right pl-3">
              <b-button type="submit" class="btn-comment px-4 py-2" :disabled="form.content.length === 0 || submitting">
                發佈
              </b-button>
            </div>
          </div>
        </b-form>
      </b-col>
    </b-row>
    <b-row class="bg-grey mx-2 pt-3 pb-3" v-else>
      <b-col class="pl-2 pr-0 " cols="auto">
        <div class="avatar-wrap">
          <ScanTraderAvatar width="60" height="60" :src="user.my_info.avatar" />
        </div>
      </b-col>
      <b-col tag="a" :href="user.loginUrl">
        <div class="border form-border bg-white">
          <div class="vertical middle text-center">
            <img class="mr-3" height="24" src="~/static/images/png_icon/mdpi/nav_logo_quants.png" srcset="~/static/images/png_icon/xhdpi/nav_logo_quants.png 2x, ~/static/images/png_icon/xxhdpi/nav_logo_quants.png 3x">
            <b-button class="st_color_link btn-login p-0 mr-0">
              登入
            </b-button>
            <span class="text-login">
              後參與討論
            </span>
          </div>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
  import { mapActions, mapGetters, mapMutations } from 'vuex'

  export default {
    props: {
      commentKind: {
        type:    String,
        default: 'activity',
      },
      identity: {
        type:    String,
        default: null,
      },
    },
    data () {
      return {
        submitting: false,
        form:       {
          content:   '',
          image:     '',
          imageFile: null,
        },
        isTextareaFocus: false,
        selectOwnRole:   null,
      }
    },
    computed: {
      ...mapGetters(['user']),
    },
    watch: {
      selectOwnRole:                      'setMyOwnRolesLastCommentReply',
      'user.myOwnRoles.lastCommentReply': 'selectLastCommentReply',
    },
    methods: {
      ...mapActions('api/upload', ['uploadFile']),
      ...mapActions('api/comment', ['createComment']),
      ...mapMutations('user', ['setMyOwnRolesLastCommentReply']),
      getTextAreaMinHeight () {
        const lines = this.form.content.split(/\r*\n/).length
        let height = (lines - 1) * 24 + 40
        if (height > 200) {
          height = 200
        }
        return `${height}px`
      },
      onBlurTextarea () {
        this.isTextareaFocus = false
      },
      onFocusTextarea () {
        this.isTextareaFocus = true
      },
      onInsertImage () {
        this.$refs.imageUpload.$el.click()
      },
      onFileChange (e) {
        const files = e.target.files || e.dataTransfer.files
        if (!files.length) return

        const maxFileSizeMb = 5
        const fileSize = files[0].size / 1024 / 1024

        if (fileSize > maxFileSizeMb) {
          this.$toast.show(`檔案大小不得超過 ${maxFileSizeMb}Mb`).goAway(3000)
          this.removeImage()
          return
        }

        if (files[0].type.match(/image.*/ig)) {
          this.createImage(files[0])
        } else {
          this.removeImage()
        }
      },
      createImage (file) {
        const reader = new FileReader()
        const vm = this

        reader.onload = e => {
          vm.form.image = e.target.result
        }
        reader.readAsDataURL(file)
      },
      removeImage () {
        this.$refs.imageUpload.$el.value = ''
        this.form.image = ''
        this.form.imageFile = null
      },
      clearForm () {
        this.form.content = ''
        this.form.image = ''
        this.form.imageFile = null
        this.$refs.imageUpload.$el.value = ''
        this.submitting = false
      },
      async addComment () {
        this.submitting = true
        const comment = {
          account: (this.selectOwnRole || this.user.my_info).identity,
          kind:    this.commentKind,
          index:   this.identity,
          content: this.form.content,
          summary: this.form.content,
        }
        if (this.form.imageFile) {
          comment.image = await this.uploadFile(this.form.imageFile)
        }
        const commentIdentity = await this.createComment(comment)
          .then(data => data.identity)
        this.clearForm()
        this.$emit('update', commentIdentity)
      },
      expandImage (e) {
        e.target.parentNode.parentNode.querySelector('.preview').click()
      },
      selectLastCommentReply () {
        if (this.$userCheck.isLogin() && _.get(this.user, 'myOwnRoles.commentReply.length', 0)) {
          const lastIndex = _.findIndex(this.user.myOwnRoles.commentReply, ['identity', this.user.myOwnRoles.lastCommentReply])
          this.selectOwnRole = this.user.myOwnRoles.commentReply[lastIndex < 0 ? 0 : lastIndex]
        }
      },
    },
    mounted () {
      this.selectLastCommentReply()
    },
  }
</script>

<style lang="scss" scoped>
  .form-insert-pic__button {
    border-radius: 100px;
  }

  .post-form {
    .form-border {
      padding: 20px;
    }
  }

  .btn-image {
    font-size: 30px;
    opacity: 0.8;
  }

  .textarea-content {
    min-height: 40px;
    height: 40px;
    border: none;
    box-shadow: none;
    outline: none;
    resize: none;

    &::-webkit-input-placeholder {
      color: $st_color_gray_dark;
    }

    &:focus::-webkit-input-placeholder {
      color: $st_color_gray_light;
    }
  }

  .image-preview {
    display: inline-block;
    position: relative;
    width: 200px;
    height: 200px;
    line-height: 200px;
    text-align: center;
    overflow: hidden;
    border-color: rgba($st_color_gray_light, .35);
    border-style: dashed;
    border-width: 2px;
    background-color: white;
    z-index: 2;

    img {
      position: relative;
      max-width: 200px;
      max-height: 200px;
      vertical-align: middle;
      transition: .4s;
      z-index: -2;
    }

    .btn-clear {
      border-radius: 50%;
      color: white;
      width: 26px;
      height: 26px;
      padding-top: 2px;
      text-align: center;
      background-color: rgba(43, 44, 55, 0.5);
      right: 5px;
      top: 5px;
      position: absolute;
      z-index: 1;
      line-height: 0;

      i {
        font-size: $st_font-size-bigger;
      }
    }

    .btn-expand {
      border-radius: 50%;
      position: absolute;
      width: 26px;
      height: 26px;
      padding-top: 1px;
      text-align: center;
      left: 5px;
      top: 5px;
      color: white;
      background-color: rgba(43, 44, 55, 0.5);
      transition-property: top, left, transform, width, height;
      transition-duration: .4s;
      line-height: 0;

      i {
        transition-property: font-size;
        transition-duration: .4s;
      }
    }

    &:after {
      transition: .4s;
      content: "";
    }

    &:hover {
      &::after {
        content: "";
        background-color: rgba(0, 0, 0, .4);
        position: absolute;
        z-index: -1;
        height: 100%;
        bottom: 0;
        left: 0;
        right: 0;
      }

      .btn-expand {
        top: 50%;
        left: 50%;
        transform: translate(-60%, -60%);
        background: transparent;

        i {
          font-size: 36px;
        }
      }
    }
  }

  .btn-comment {
    border-radius: 2px;
    background-color: $st_color_main_light;
    color: white;
    text-align: center;
    padding: 5px 30px;
    font-size: $st_font-size-base;
    font-weight: $st_font-weight-medium;
    letter-spacing: $st_font_letter-spacing;
  }

  .frame-comment {
    border: solid 2px $st_color_gray_lighter;
    border-radius: 2px;
  }

  .bg-white {
    background: white;
  }

  .pointer {
    cursor: pointer;
  }

  .btn-login {
    background: none;
    border: none;
    color: #0056b3;
  }

  .text-login {
    color: #acbbc3;
    font-size: 1rem;
  }

  .bg-grey {
    background: #f5f6fa;
  }
</style>
