<template>
  <div class="commonFroalaEditor">
    <froala :id="`froala-editor-${randomId}`" :tag="'textarea'" ref="froala" :config="config" v-model="inputVal" />
  </div>
</template>

<script>
  import { mapActions } from 'vuex'

  export default {
    props: {
      value: {
        type:    String,
        default: '',
      },
      placeholderText: {
        type:    String,
        default: '寫點內容...揮灑你獨特的觀點吧！',
      },
    },
    data: function() {
      const randomId = this.$utils.generateId(12)
      return {
        randomId,
        inputVal: this.value,
        config:   {
          key:               this.$store.getters.siteSetting.froalaEditorV2Key,
          autofocus:         true,
          disableRightClick: true,
          editorClass:       `froala-editor froala-editor-${randomId}`,
          enter:             $.FroalaEditor.ENTER_BR,
          htmlAllowedTags:   [
            'a',
            'b',
            'blockquote',
            'br',
            'div',
            'embed',
            'h1',
            'h2',
            'hr',
            'i',
            'iframe',
            'img',
            'li',
            'ol',
            'p',
            'small',
            'source',
            'span',
            'strike',
            'strong',
            'sub',
            'sup',
            'table',
            'tbody',
            'td',
            'tfoot',
            'th',
            'thead',
            'tr',
            'u',
            'ul',
            'markettag',
          ],
          pluginsEnabled:  [],
          placeholderText: this.placeholderText,
          toolbarButtons:  [],
          language:        'zh_tw',
          events:          {
            'froalaEditor.initialized': (e, editor) => {
              editor.$el
                .find('markettag')
                .attr({
                  class:                  'mention fr-deletable',
                  contenteditable:        'false',
                  'data-denotation-char': '$',
                })
                .text('$')
              const config = this.$tribute.collection.find(item => item)
              config.trigger = '$'
              config.lookup = 'value'
              config.selectTemplate = item => {
                const newElem = document.createElement('markettag')
                newElem.className = 'mention fr-deletable'
                newElem.innerHTML = '$'
                newElem.setAttribute('contenteditable', 'false')
                newElem.setAttribute('data-denotation-char', '$')
                newElem.setAttribute('data-exchangeName', item.original.exchangeName)
                newElem.setAttribute('data-id', item.original.id)
                newElem.setAttribute('data-namePrimary', item.original.namePrimary)
                newElem.setAttribute('data-tickerSymbol', item.original.tickerSymbol)
                newElem.setAttribute('data-value', item.original.value)
                return newElem.outerHTML
              }
              config.menuItemLimit = 100
              config.values = this.renderMarketTag
              config.noMatchTemplate = () => '<span style:"visibility: hidden;"></span>'
              this.$tribute.attach(editor.$el)
              editor.events.on(
                'keydown',
                e => {
                  if (e.which === $.FroalaEditor.KEYCODE.ENTER && this.$tribute.isActive) {
                    return false
                  }
                  return true
                },
                true,
              )
              editor.events.on('destroy', () => {
                this.$tribute.detach(editor.$el)
                this.$tribute.menu = null
              })
              editor.$el.one('tribute-active-true', () => {
                this.$tribute.menu.classList.add('darkMode')
              })
            },
            'froalaEditor.paste.after': (e, editor) => {
              this.reformatMarketTag(editor)
            },
            'froalaEditor.focus': (e, editor) => {
              editor.selection.restore()
              this.$emit('focus', e)
            },
            'froalaEditor.blur': (e, editor) => {
              editor.selection.save()
              this.$emit('blur', e)
            },
          },
        },
      }
    },
    watch: {
      value (val) {
        this.inputVal = val
      },
      inputVal (val) {
        this.$emit('input', val)
      },
    },
    methods: {
      ...mapActions('api/tickerSymbol', ['getTickerSymbols']),
      renderMarketTag (searchTerm, callback) {
        if (searchTerm && searchTerm.trim().length > 0) {
          this.getTickerSymbols({
            text:   searchTerm,
            fields: ['name.primary', 'tickerSymbol', 'tradeType', 'exchange.name'],
          }).then(data => {
            if (!data.error && _.get(data, 'data.length', 0)) {
              callback(
                data.data.map(tickerSymbol => ({
                  tickerSymbol: tickerSymbol.tickerSymbol,
                  namePrimary:  tickerSymbol.name.primary,
                  exchangeName: tickerSymbol.exchange.name,
                  id:           `${tickerSymbol.exchange.name}:${tickerSymbol.tickerSymbol}`,
                  value:        `${tickerSymbol.name.primary}(${tickerSymbol.exchange.name}:${tickerSymbol.tickerSymbol})`,
                })),
              )
            } else {
              callback([])
            }
          })
        } else {
          callback([])
        }
      },
      reformatMarketTag (editor) {
        editor.$el
          .find('markettag')
          .not('.mention')
          .attr({
            class:                  'mention fr-deletable',
            contenteditable:        'false',
            'data-denotation-char': '$',
          })
          .text('$')
      },
      // 父元件可以 call 這個方法，在編輯器中插入 $ 符號
      insertDollarSign () {
        const editorEl = this.$refs.froala.getEditor()()
        editorEl.froalaEditor('selection.restore')
        const caretSelection = editorEl.froalaEditor('selection.get')
        const text = _.get(caretSelection, 'anchorNode.textContent')
        const notAllowedBlank = /&nbsp;$/.test(text) || text === ''
        editorEl.froalaEditor('html.insert', notAllowedBlank ? '$' : '&nbsp;$', true)
      },
    },
  }
</script>

<style lang="scss" scoped>
.commonFroalaEditor {
  /deep/ {
    markettag.mention[data-tickerSymbol] {
      &:after {
        content: attr(data-namePrimary) '(' attr(data-tickerSymbol) ')';
      }
    }

    .froala-editor {
      .fr-toolbar,
      .fr-sticky-dummy {
        display: none;
      }

      .fr-wrapper {
        background-color: transparent;
        box-shadow: none;
      }

      .fr-view {
        padding: 0;
      }

      .fr-placeholder {
        @important {
          padding: 0;
        }
      }
    }
  }
}
</style>
