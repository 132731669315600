<template>
  <nuxt-link :to="$userRoute({ name: 'u-uid-service' }, cardData.account)" class="card-link">
    <b-card
      noBody :title="cardData.title" :imgAlt="cardData.title" imgTop tag="article"
      class="mb-2 h-100 cover-image-block">
      <div class="frame-cover">
        <div
          class="w-100 img-cover"
          :style="{ backgroundImage: 'url(' + (_.get(cardData, 'kind', null) === 'organization' ? (cardData.organization.cover || cardData.cover) : cardData.cover) + ')' }"
          v-if="_.get(cardData, 'kind', null) === 'organization' ? (cardData.organization.cover || cardData.cover) : cardData.cover" />
      </div>
      <b-card-header>
        <div class="name align-middle mb-1 " v-if="(cardData.account.name || {}).nick">
          <ScanTraderAvatar class="d-inline cover photo-profile" :src="cardData.account.avatar" lazy />
          <nuxt-link v-if="$userCheck.is('service', cardData.account) && _.get(cardData.account, 'belongOrgInfo.name.nick', 0)" :to="$userRoute({ name: 'o-oas' }, cardData.account.belongOrgInfo)">
            <svg-icon
              v-b-tooltip.hover.focus.top="cardData.account.belongOrgInfo.name.nick"
              :width="$userBadge(cardData.account.belongOrgInfo, 'width')"
              :height="$userBadge(cardData.account.belongOrgInfo, 'height')"
              :name="$userBadge(cardData.account.belongOrgInfo, 'name')" />
          </nuxt-link>
          <svg-icon
            v-else
            :name="$userBadge(cardData.account, 'name')"
            :width="$userBadge(cardData.account, 'width')"
            :height="$userBadge(cardData.account, 'height')" />
          <span class="align-middle name-nick-frame st_color_main_light ml-1">
            <span class="align-middle name-nick st_color_main_light">
              {{ cardData.account.name.nick }}{{ (cardData.account.belongOrg && _.get(cardData.account, 'name.real', '')) ? `．${cardData.account.name.real || ''}` : '' }}
            </span>
          </span>

          <b-button
            @click.prevent="updateCollectionAccountState"
            v-if="!$userCheck.serialIsOwn(cardData.account.serial)"
            variant="none" class="p-0">
            <i
              class="material-icons align-middle icon-heart"
              :class="collectionAccount?'st_color_sub':'st_color_gray_light'">
              favorite
            </i>
          </b-button>
        </div>
        <div class="title mt-1">
          {{ _.get(cardData, 'kind', null) === 'organization' ? (cardData.organization.title || cardData.title) : cardData.title }}
        </div>
      </b-card-header>

      <!-- this frame not display on mobile -->
      <b-card-body class="d-none d-md-block">
        <div class="card-text mb-3 st_color_gray_dark" v-html="$utils.filterAllHtmlTags(_.get(cardData, 'kind', null) === 'organization' ? (cardData.organization.description || cardData.description) : cardData.description)" />
        <div class="categories">
          <nuxt-link :to="''" class="mr-2 category" v-for="category in (_.get(cardData, 'kind', null) === 'organization' ? (cardData.organization.categories || cardData.categories) : cardData.categories)" :key="category.identity">
            {{ category.name }}
          </nuxt-link>
        </div>
      </b-card-body>

      <!-- mobile -->
      <div v-if="cardData.haveChannel" class="d-flex mb-2 d-md-none px-2 w-100">
        <div class="d-flex align-items-center">
          <i class="heart material-icons align-middle icon-heart st_color_sub">
            favorite
          </i>
          <div class="total-value st_color_sub">
            {{ followed || 0 }}
          </div>
        </div>
        <div class="d-flex align-items-center ml-auto">
          <i class="fab fa-hotjar st_color_sub" v-if="!hideSubscriptions" />
          <div class="total-value st_color_sub" v-if="!hideSubscriptions">
            {{ subscriptions || 0 }}
          </div>
        </div>
      </div>

      <!-- desktop -->
      <div v-if="cardData.haveChannel" class="d-none d-md-block">
        <b-row class="mb-3 mt-4">
          <b-col cols="6" class="d-flex align-items-center justify-content-center">
            <i class="heart material-icons align-middle icon-heart st_color_sub">
              favorite
            </i>
            <div class="total-value st_color_sub">
              {{ followed || 0 }}
            </div>
          </b-col>
          <b-col cols="6" class="d-flex align-items-center justify-content-center">
            <i class="fab fa-hotjar st_accent-none-big" v-if="!hideSubscriptions" />
            <div class="total-value st_color_sub" v-if="!hideSubscriptions">
              {{ subscriptions || 0 }}
            </div>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </nuxt-link>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'

  export default {
    props: {
      cardData: {
        type:    Object,
        default: null,
      },
      needGetCollection: {
        type:    Boolean,
        default: false,
      },
    },
    data () {
      return {
        collectionAccount: !this.needGetCollection && this.cardData && this.cardData.account ? this.cardData.account.collection || null : null,
      }
    },
    computed: {
      ...mapGetters(['user']),
      followed () {
        return this.numberFormatter(this.cardData.account.followed)
      },
      subscriptions () {
        return this.numberFormatter(this.cardData.subscriptions)
      },
      collectionAccountState () {
        return !_.isNil(this.collectionAccount) || false
      },
      hideSubscriptions () {
        return !!(this.cardData.hidden && this.cardData.hidden.subscriptions)
      },
    },
    methods: {
      ...mapActions('api/collection', ['getCollection', 'createCollection', 'deleteCollection']),
      numberFormatter (num) {
        if (num >= 10000) {
          return `${Math.round(num / 1000) / 10}萬`
        } else if (num >= 1000) {
          // n=Math.round(num / 100) / 10 + ""
          const n = `${num}`
          const arr = n.split('.')
          const re = /(\d{1,3})(?=(\d{3})+$)/g
          return arr[0].replace(re, '$1,') + (
            arr.length === 2 ? `.${arr[1]}` : '')
        }
        return num
      },
      updateCollectionAccountState () {
        if (this.$userCheck.isLogin()) {
          const { collectionAccount } = this
          if (_.isNil(collectionAccount)) {
            this.collectionAccount = true
            // eslint-disable-next-line vue/no-mutating-props
            this.cardData.account.followed++
            const collection = {
              kind:    'account',
              index:   this.cardData.account.identity ? this.cardData.account.identity : this.cardData.account,
              account: this.user.my_info.identity,
            }
            this.createCollection(collection)
              .then(data => {
                this.collectionAccount = _.isNil(data.error) ? data.identity : null
              })
          } else {
            this.collectionAccount = null
            // eslint-disable-next-line vue/no-mutating-props
            this.cardData.account.followed--
            const collectionOption = {
              identity: collectionAccount,
            }
            this.deleteCollection(collectionOption)
          }
        } else {
          this.$toast.show('登入後即可關注用戶')
            .goAway(1500)
        }
      },
    },
    mounted () {
      if (this.needGetCollection && this.$userCheck.isLogin()) {
        const collectionAccountOption = {
          kind:    'account',
          index:   this.cardData.account.identity ? this.cardData.account.identity : this.cardData.account,
          account: this.user.my_info.identity,
        }
        this.getCollection(collectionAccountOption)
          .then(data => {
            this.collectionAccount = !data.error ? data.identity : null
          })
      }
    },
  }
</script>

<style lang="scss" scoped>
.heart {
  font-size: $st_font-size-base;

  @include media-breakpoint-up(md) {
    font-size: $st_font-size-big;
  }
}

.card {
  box-shadow: 0 0 6px 0 $st_color_gray_light;
  border: none;
  border-radius: 2px;
  overflow: hidden;
  margin: 0 auto;

  .card-img-top {
    width: auto;
  }

  .card-header {
    background: none;
    border: none;

    .name {
      color: $st_color_main_light;
    }

    .title {
      font-weight: $st_font-weight-medium;
      font-size: $st_font-line-height-smaller;
      letter-spacing: 1px;
      color: $st_color_main_light;
      text-overflow: ellipsis;
      line-height: 1.5rem;
      min-height: 3rem;
      max-height: 3rem;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;

      @include media-breakpoint-down(sm) {
        color: #2a3260;
      }
    }

  }

  .card-body {
    padding: 0 10px;

    .card-text {
      min-height: 4.2rem;
      position: relative;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      overflow: hidden;
      line-height: 1.4rem;
      height: 40px;
      font-size: $st_font-size-small;
      font-weight: $st_font-weight-light;
    }

    .categories {
      line-height: 1.5rem;
      min-height: 1.5rem;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      overflow: hidden;
    }
  }

  .card-footer {
    background: none;
    border: none;
    padding: 12px 0;
    text-align: center;

    span {
      font-size: 12px;
      color: #869ca6;
    }
  }
}

.name .badge {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background: $st_color_main;
  display: inline-block;
}

.card-header {
  padding: 10px 10px;
}

.name {
  position: relative;
}

.name .icon-heart {
  position: absolute;
  top: 10px;
  right: 0;
  font-size: 19px;
}

.total-text {
  font-size: $st_font-size-small;
  font-weight: $st_font-weight-medium;
  letter-spacing: $st_font_letter-spacing;

  @include media-breakpoint-down(sm) {
    display: inline-block;
  }
}

.total-value {
  letter-spacing: $st_font_letter-spacing;
  font-weight: $st_font-weight-medium;
  font-size: $st_font-size-bigger;
  margin-left: 5px;

  @include media-breakpoint-down(sm) {
    font-size: $st_font-size-base;
    display: inline-block;
  }
}

.category {
  border-radius: 2px;
  font-size: $st_font-size-smaller;
  color: white;
  padding: 3px 6px;
  background-color: rgba(43, 44, 55, 0.6);
}

.name-nick-frame {
  display: inline-block;
  max-width: calc(100% - 100px);

  @include media-breakpoint-down(sm) {
    max-width: calc(100% - 90px);
  }
}

.name-nick {
  max-width: 100%;
  overflow: hidden;
  max-height: 24px;
  height: 24px;
  font-size: $st_font-size-base;
  letter-spacing: $st_font_letter-spacing;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  line-height: 24px;
  text-overflow: ellipsis;
}

.frame-cover {
  overflow: hidden;
}

.img-cover {
  padding-bottom: 56.25%;
  background-size: cover;
  background-position: center;
  transition: all .2s;
}

.photo-profile {
  width: 40px;
  height: 40px;

  @include media-breakpoint-down(sm) {
    width: 27px;
    height: 27px;
  }
}

.card-link {
  &:hover {
    .img-cover {
      transform: scale(1.2);
    }
  }
}
</style>
