<template>
  <nuxt-link :to="{ name: 'article-id', params: { id: cardData.identity } }" class="w-100">
    <!-- mobile -->
    <div class="card-type-activity d-md-none">
      <b-container class="py-3 px-4">
        <b-row>
          <b-col cols="12" sm="12" class="card-left p-0">
            <b-row class="no-gutters mb-3">
              <b-col cols="3" class="pr-2">
                <div class="w-100 img-cover" :style="{ backgroundImage: 'url(' + cardData.cover + ')' }" v-if="cardData.cover" />
              </b-col>
              <b-col cols="9">
                <div class="title st_color_main_light">
                  {{ cardData.title }}
                </div>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12">
                <div class="summary st_color_gray_dark" v-if="!restrictState">
                  {{ cardData.summary }}
                </div>
              </b-col>
            </b-row>
          </b-col>
          <!-- if is subsribe post -->
          <div v-if="cardData.channels.length > 0">
            <!-- if not subsribe show subscribe -->
            <b-col cols="12" sm="12" class="card-right p-2" v-if="restrictState">
              <div class="restrict text-right">
                <svg-icon name="icon_lock_blue" width="16" height="16" />
                (訂閱用戶限定)
              </div>
            </b-col>
            <!-- if subscribed -->
            <b-col cols="12" sm="12" class="px-2 mt-2" v-if="!restrictState">
              <div class="tags w-100-break" v-if="cardData.channels.length > 0">
                <span class="btn-subscribe st_color_sub align-middle">
                  <i class="fa fa-book-open align-middle mr-1 st_color_sub d-inline" />
                  <span class="st_color_sub align-middle btn-subscribe-text d-inline">
                    訂閱
                  </span>
                </span>
              </div>
            </b-col>
          </div>
        </b-row>
      </b-container>
    </div>

    <!-- desktop -->
    <div class="card-type-activity d-none d-md-block pb-2">
      <div class="mb-1">
        <div class="w-100 img-cover" :style="{ backgroundImage: 'url(' + cardData.cover + ')' }" v-if="cardData.cover" />
      </div>
      <b-container class="py-1 px-3">
        <b-row>
          <b-col cols="12">
            <div class="d-flex align-items-center">
              <div class="flex-fill title st_color_main_light">
                {{ cardData.title }}
              </div>
            </div>
          </b-col>
          <b-col cols="12" class="summary st_color_gray_dark text-left mt-2" v-if="!restrictState">
            {{ cardData.summary }}
          </b-col>
          <!-- if is subsribe post -->
          <div v-if="cardData.channels.length > 0">
            <!-- if not subsribe show subscribe -->
            <b-col cols="12" sm="12" class="card-right p-2" v-if="restrictState">
              <div class="restrict text-right">
                <svg-icon name="icon_lock_blue" width="16" height="16" />
                (訂閱用戶限定)
              </div>
            </b-col>
            <!-- if subscribed -->
            <b-col cols="12" sm="12" class="px-2 mt-2" v-if="!restrictState">
              <div class="tags w-100-break" v-if="cardData.channels.length > 0">
                <span class="btn-subscribe st_color_sub align-middle">
                  <i class="fa fa-book-open align-middle mr-1 st_color_sub d-inline" />
                  <span class="st_color_sub align-middle btn-subscribe-text d-inline">
                    訂閱
                  </span>
                </span>
              </div>
            </b-col>
          </div>
        </b-row>
      </b-container>
      <b-container class="py-1 px-3" v-if="activity">
        <b-row>
          <b-col cols="12" class="text-right text-bottom st_color_gray_dark">
            <span class="align-middle mr-3">
              瀏覽人數 {{ cardData.view }}
            </span>
            <i class="material-icons align-middle mr-1 align-middle">
              thumb_up
            </i>
            <span class="pl-1 align-middle mr-3">
              {{ cardData.likes || 0 }}
            </span>
            <i class="material-icons align-middle mr-1">
              chat_bubble
            </i>
            <span class="align-middle">
              {{ cardData.commentReply || cardData.comments || 0 }}
            </span>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </nuxt-link>
</template>

<script>
  export default {
    props: {
      cardData: {
        type:    Object,
        default: null,
      },
      activity: {
        type:    Object,
        default: null,
      },
      restrict: {
        type:    Boolean,
        default: false,
      },
    },
    data () {
      return {}
    },
    computed: {
      mediaIcon () {
        let iconName = ''
        switch (this.cardData.kind) {
          case 'video':
            iconName = 'icon_video'
            break
          case 'audio':
            iconName = 'icon_voice'
            break
          default:
            break
        }
        return iconName
      },
      restrictState () {
        if (this.cardData.channels.length > 0) {
          // is user subscribed
          if (this.cardData.content) {
            return false
          }
          return true
        }
        return false
      },
    },
    methods: {},
  }
</script>

<style lang="scss" scoped>
  .card-type-activity {
    border-radius: 2px;
    border: solid 2px $st_color_gray_lighter;
    background: rgba(240, 241, 243, 0.5);

    .img-cover {
      width: 100%;
      padding-bottom: 56.25%;
      background-size: cover;
      background-position: center;
    }

    .read {
      position: absolute;
      bottom: 0;
      left: 0;
      text-align: center;
      width: 70px;
      height: 28px;
      line-height: 28px;
      background-color: rgba(61, 74, 81, 0.7);
      color: #fff;
    }

    .media {
      width: 50px;
      height: 40px;
      position: absolute;
      right: 5px;
      bottom: 5px;
      opacity: 0.8;
    }

    .title {
      font-size: $st_font-size-big;
      font-weight: $st_font-weight-medium;
      letter-spacing: $st_font_letter-spacing;
      word-break: break-all;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;

      @media only screen and (max-width: 480px) {
        font-size: $st_font-size-base;
      }
    }

    .summary {
      font-size: $st_font-size-big;
      letter-spacing: $st_font_letter-spacing;
      word-break: break-all;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;

      @media only screen and (max-width: 480px) {
        font-size: $st_font-size-small;
      }
    }
  }

  .text-bottom {
    font-size: $st_font-size-smaller;
    letter-spacing: $st_font_letter-spacing;

    i {
      font-size: $st_font-size-base;
      opacity: 0.6;
    }
  }

  .categories {
    list-style: none;
    padding: 0;
    margin: 0;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.7px;

    li {
      color: $st_color_sub;
      display: inline-block;
      margin-bottom: 5px;
    }

    a {
      color: $st_color_sub;
      display: block;
      margin-right: 3px;
      background: none;
      font-size: 12px;
    }
  }

  .img-frame {
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;
    overflow: hidden;
  }

  .btn-subscribe {
    padding: 3px 8px;
    border-radius: 15px;
    display: inline-block;
    background: rgba(234, 185, 133, 0.1);
    font-size: $st_font-size-small;
    font-weight: $st_font-weight-medium;
    letter-spacing: $st_font_letter-spacing;

    .btn-subscribe-text {
      padding-bottom: 1px;
    }
  }

  .channel-text {
    font-size: $st_font-size-small;
    font-weight: $st_font-weight-medium;
    letter-spacing: $st_font_letter-spacing;

    &:hover,
    &:active {
      color: $st_color_sub;
    }
  }
</style>
