<template>
  <b-container v-if="account" fluid class="frame-account">
    <nuxt-link :to="$userRoute({ name: 'u-uid'}, account)">
      <!-- mobile  -->
      <b-row class="pt-3 pb-3 d-md-none">
        <b-col cols="12" class="relative">
          <div class="text-center">
            <ScanTraderAvatar
              class="mb-2" width="64" height="64" :src="account.avatar" lazy
              :offset="180" />
            <div class="name st_color_main_light mb-1">
              <svg-icon
                :name="$userBadge(account, 'name')" :width="$userBadge(account, 'width')"
                :height="$userBadge(account, 'height')"
                v-if="$userCheck.are(['service', 'organization'], account)" class="align-middle" />
              <span class="align-middle">
                {{ _.get(account, 'name.nick', '') }}{{ (account.belongOrg && _.get(account, 'name.real', '')) ? `．${account.name.real || ''}` : '' }}
              </span>
            </div>
            <div class="brief st_color_gray_darker">
              粉絲數： {{ account.followed || 0 }} 位
              <span class="mx-1 d-none">
                |
              </span><span class="d-none">
                觀點數： {{ account.articles || 0 }} 篇
              </span>
            </div>
            <button class="btn-follow mt-2" v-if="!$userCheck.serialIsOwn(account.serial)" @click.prevent="updateCollectionAccountState" :class="{actived:collectionAccountState}">
              <i class="material-icons align-middle icon-heart st_color_sub" v-if="collectionAccountState">
                favorite
              </i>
              <i class="material-icons align-middle icon-heart st_color_gray_dark" v-if="!collectionAccountState">
                favorite
              </i>

              <span class="align-middle" v-if="collectionAccountState">
                關注中
              </span>
              <span class="align-middle" v-if="!collectionAccountState">
                加關注
              </span>
            </button>
          </div>
        </b-col>
      </b-row>

      <!-- desktop -->
      <b-row class="pt-3 pb-3 d-none d-md-block">
        <b-col cols="12">
          <div class="d-flex align-items-center">
            <div class="text-left mr-3 ml-1">
              <ScanTraderAvatar
                class="mb-2" width="64" height="64" :src="account.avatar" lazy
                :offset="180" />
            </div>
            <div class="flex-fill">
              <div>
                <div class="name st_color_main_light mb-1 d-flex align-items-center">
                  <svg-icon
                    :name="$userBadge(account, 'name')" :width="$userBadge(account, 'width')"
                    :height="$userBadge(account, 'height')" class="mr-2 align-middle" v-if="$userCheck.are(['service', 'organization'], account)" />
                  <nuxt-link v-if="$userCheck.is('service', account) && _.get(account, 'belongOrgInfo.name.nick', 0)" :to="$userRoute({ name: 'o-oas' }, account.belongOrgInfo)">
                    <svg-icon
                      v-b-tooltip.hover.focus.top="account.belongOrgInfo.name.nick"
                      class="mr-2 align-middle"
                      :width="$userBadge(account.belongOrgInfo, 'width')"
                      :height="$userBadge(account.belongOrgInfo, 'height')"
                      :name="$userBadge(account.belongOrgInfo, 'name')" />
                  </nuxt-link>
                  <span class="align-middle expert-nick">
                    {{ _.get(account, 'name.nick', '') }}{{ (account.belongOrg && _.get(account, 'name.real', '')) ? `．${account.name.real || ''}` : '' }}
                  </span>
                </div>
                <div class="brief st_color_gray_darker">
                  粉絲數： {{ account.followed || 0 }} 位
                  <span class="mx-1 d-none">
                    |
                  </span><span class="d-none">
                    觀點數： {{ account.articles || 0 }} 篇
                  </span>
                </div>
              </div>
            </div>
            <div class="text-right flex-0-auto">
              <button class="btn-follow" v-if="!$userCheck.serialIsOwn(account.serial)" @click.prevent="updateCollectionAccountState" :class="{actived:collectionAccountState}">
                <i class="material-icons align-middle icon-heart" :class="collectionAccountState ? 'st_color_sub' : 'st_color_gray_light' ">
                  favorite
                </i>
                <span class="align-middle" v-if="collectionAccountState">
                  關注中
                </span>
                <span class="align-middle" v-if="!collectionAccountState">
                  加關注
                </span>
              </button>
            </div>
          </div>
        </b-col>
      </b-row>
    </nuxt-link>
  </b-container>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'

  export default {
    props: {
      account: {
        type:    Object,
        default: null,
      },
    },
    data () {
      return {
        collectionAccount: null,
      }
    },
    computed: {
      ...mapGetters(['user']),
      collectionAccountState () {
        return !_.isNil(this.collectionAccount) || false
      },
    },
    methods: {
      ...mapActions('api/collection', ['getCollection', 'createCollection', 'deleteCollection']),
      updateCollectionAccountState () {
        if (this.user.serial !== 0) {
          const { collectionAccount } = this
          if (_.isNil(collectionAccount)) {
            this.collectionAccount = true
            const collection = {
              kind:    'account',
              index:   this.account.identity,
              account: this.user.my_info.identity,
            }
            this.createCollection(collection)
              .then(data => {
                this.collectionAccount = _.isNil(data.error) ? data.identity : null
              })
          } else {
            this.collectionAccount = null
            const collectionOption = {
              identity: collectionAccount,
            }
            this.deleteCollection(collectionOption)
          }
        } else {
          this.$toast.show('登入後即可關注用戶')
            .goAway(1500)
        }
      },
    },
    async mounted () {
      if (this.user.serial !== 0) {
        const collectionAccountOption = {
          kind:    'account',
          index:   this.account.identity,
          account: this.user.my_info.identity,
        }
        this.collectionAccount = await this.getCollection(collectionAccountOption)
          .then(data => (!data.error ? data.identity : null))
      }
    },
  }
</script>

<style lang="scss" scoped>
  .frame-account {
    border-radius: 2px;
    border: solid 2px $st_color_gray_lighter;
    background-color: rgba(240, 241, 243, 0.5);
  }

  .name {
    font-size: $st_font-size-base;
    letter-spacing: $st_font_letter-spacing;
  }

  .brief {
    font-size: $st_font-size-small;
    font-weight: $st_font-weight-light;
    letter-spacing: $st_font_letter-spacing;
  }

  .relative {
    position: relative;
  }

  .btn-follow {
    font-size: $st_font-size-base;
    color: $st_color_gray_dark;
    background: white;
    border: solid 2px rgba(184, 188, 195, 0.5);
    padding: 5px 15px;
    border-radius: 4px;
    cursor: pointer;

    i {
      margin-right: 3px;
    }

    @media only screen and (max-width: 480px) {
      position: absolute;
      right: 5px;
      top: -5px;
      padding: 5px 8px;
    }

    &:hover {
      background: rgba($st_color_gray_lighter, .3);
      border: solid 2px $st_color_gray_lighter;
    }

    &.actived {
      color: $st_color_sub;
      background: white;
      border: solid 2px rgba(184, 188, 195, 0.5);
    }
  }

  .flex-0-auto {
    flex: 0 0 auto;
  }

  .line-break-1 {
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    word-break: break-all;
  }
</style>
